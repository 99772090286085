/* eslint-disable react/jsx-no-target-blank */
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as ServerConfiguration from '../server-configuration'
import * as Constants from '../constants'
import { AppVersion } from '../app-version'
import { HtmlContent } from '../html-content'
import { TrafficJunky } from '../traffic-junky'
import { useBrand } from '../use-brand'
import { useCookieConsent } from '../use-cookie-consent'
import { useGameInfoUrlQuery } from '../use-game-info-url-query'

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

export function Footer() {
  const i18n = I18n.useI18n()
  const gameInfoQuery = useGameInfoUrlQuery()
  const location = ReactRouter.useLocation()
  const { country } = ServerConfiguration.useContext()
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const license = ReactRedux.useSelector(state =>
    Jurisdiction.getLicense(state.jurisdiction)
  )
  const subBrand = ReactRedux.useSelector(state =>
    Configuration.getSubBrand(state.configuration)
  )
  const brand = useBrand()
  const cookieConsentEnabled = useCookieConsent()
  const isMobile = R.equals(Constants.ClientType.MOBILE_BROWSER, clientType)
  const hideLegalText =
    subBrand === 'playboom24' ||
    (subBrand === 'playboom' && R.includes(country.alpha2, ['NL', 'SE'])) ||
    brand === Constants.Brands.CASINOSKY ||
    brand === Constants.Brands.PARADISEPLAY
  const hideNodaLogo =
    subBrand === 'playboom24' ||
    brand === Constants.Brands.CASINOSKY ||
    brand === Constants.Brands.PARADISEPLAY
  /**
   * Hides the footer:
   * - When playing any game on mobile
   * - You're on the Experts Area route
   */

  if (
    (isMobile && R.includes(gameInfoQuery.mode, ['classic', 'blitz'])) ||
    R.includes('experts-area', location.pathname)
  ) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <Common.Box
        as="nav"
        color="g-text"
        display="flex"
        flexWrap="wrap"
        fontSize={1}
        justifyContent="center"
        pt={0}
      >
        <ReactRouter.Link
          to="/terms-and-conditions"
          data-testid="footer.link.terms"
        >
          <LinkWrapper>
            {i18n.translate('footer.terms-and-conditions')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/about" data-testid="footer.link.about">
          <LinkWrapper>{i18n.translate('footer.about-us')}</LinkWrapper>
        </ReactRouter.Link>
        {cookieConsentEnabled && (
          <ReactRouter.Link
            to="/cookie-policy"
            data-testid="footer.link.cookie-policy"
          >
            <LinkWrapper>{i18n.translate('footer.cookie-policy')}</LinkWrapper>
          </ReactRouter.Link>
        )}
        <ReactRouter.Link to="/faq" data-testid="footer.link.faq">
          <LinkWrapper>{i18n.translate('footer.faq')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/privacy-policy"
          data-testid="footer.link.privacy-policy"
        >
          <LinkWrapper>{i18n.translate('footer.privacy-policy')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/responsible-gaming"
          data-testid="footer.link.responsible-gaming"
        >
          <LinkWrapper>
            {i18n.translate('footer.responsible-gaming')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/dispute-resolution"
          data-testid="footer.link.dispute-resolution"
        >
          <LinkWrapper>
            {i18n.translate('footer.dispute-resolution')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/anti-money-laundering"
          data-testid="footer.link.anti-money-laundering"
        >
          <LinkWrapper>
            {i18n.translate('footer.anti-money-laundering')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/fairness-rng-testing-methods"
          data-testid="footer.link.fairness-rng-testing-methods"
        >
          <LinkWrapper>
            {i18n.translate('footer.fairness-rng-testing-methods')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/kyc-policies"
          data-testid="footer.link.kyc-policies"
        >
          <LinkWrapper>{i18n.translate('footer.kyc-policies')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/self-exclusion"
          data-testid="footer.link.self-exclusion"
        >
          <LinkWrapper>{i18n.translate('footer.self-exclusion')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/accounts-payouts-bonuses"
          data-testid="footer.link.accounts-payouts-bonuses"
        >
          <LinkWrapper>
            {i18n.translate('footer.accounts-payouts-bonuses')}
          </LinkWrapper>
        </ReactRouter.Link>
        {/* <a
          href="https://heropartners.io/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="footer.link.affiliate"
        >
          <LinkWrapper>{i18n.translate('footer.affiliate')}</LinkWrapper>
        </a> */}
      </Common.Box>
      {!hideLegalText && (
        <Common.Box
          fontSize={1}
          color="g-text"
          opacity="0.5"
          pt={2}
          textAlign="center"
          mx="auto"
          maxWidth="700px"
        >
          <HtmlContent
            as="small"
            html={{
              __html: i18n.translate('footer.legal-text.mga'),
            }}
            data-testid="footer.legal-text.mga"
          />
        </Common.Box>
      )}
      <Common.Box
        pt={0}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        <Common.Space p={0}>
          {license === Constants.GamblingLicenseIds.CURACAO &&
          subBrand !== 'playboom24' ? (
            <iframe
              src="https://licensing.gaming-curacao.com/validator/?lh=00c75f114361784a2ca1b963549a5cdc&rlh=495e7508135b10af4415038f76505bc1&template=tseal"
              width="150"
              height="50"
              style={{ border: 'none' }}
            ></iframe>
          ) : null}
        </Common.Space>
        <Common.Space p={0}>
          <a
            href="https://www.gamblingtherapy.org/en"
            target="_blank"
            data-testid="footer.gambling-therapy.link"
            rel="noopener noreferrer"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={
                <Common.Text fontSize={1}>Gambling Therapy</Common.Text>
              }
            >
              <img
                src={i18n.translate('gamcare-logo')}
                alt="Gambling Therapy"
                height="34"
                data-testid="footer.gamcare-logo"
              />
            </LazyLoad>
          </a>
        </Common.Space>
        <TrafficJunky />

        <Common.Space p={0}>
          <ReactRouter.Link
            to="/responsible-gaming"
            data-testid="footer.responsible-gaming.link"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={<Common.Text fontSize={1}>18+</Common.Text>}
            >
              <img
                src={i18n.translate('18-plus')}
                alt="18+"
                height="34"
                data-testid="footer.18-plus-logo"
              />
            </LazyLoad>
          </ReactRouter.Link>
        </Common.Space>
        <Common.Space p={0}>
          <a
            href="https://casinohallen.se/"
            target="_blank"
            data-testid="footer.casinohallen-approved.link"
            rel="noopener noreferrer"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={
                <Common.Text fontSize={1}>Casinohallen Approved</Common.Text>
              }
            >
              <img
                src={i18n.translate('casinohallen-approved')}
                alt="CasinoHallen Approved"
                height="34"
                data-testid="footer.casinohallen-approved"
              />
            </LazyLoad>
          </a>
        </Common.Space>
        {!hideNodaLogo ? (
          <Common.Box
            as="img"
            alt="Noda"
            height="34px"
            src={
              brand === Constants.Brands.PLAYBOOM
                ? '/images/noda-logo-light.png'
                : '/images/noda-logo-dark.png'
            }
          />
        ) : null}
      </Common.Box>
      <AppVersion />
    </Common.Box>
  )
}

// for @loadable/components
export default Footer
